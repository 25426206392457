export interface ChosenFile {
  name: string;
  localUrl: string;
  remoteUrl: string;
  size: string;
  file: File;
  isUploading: boolean;
  isUploaded: boolean;
  isUploadError: boolean;
}

export interface GetTableDataRequest {
  accountId: string;
  userId?: string;
  categoryId?: string;
  pageable: Pageable;
}

export interface GetTableDataWithSearchRequest extends GetTableDataRequest {
  search?: string;
  status?: string;
  hasInfiniteScroll?: boolean;
  hasVideos?: boolean;
}

export interface GetTableDataResponse<T> {
  items: T[];
  totalPages: number;
  totalItems: number;
  page: number;
  size: number;
}

export interface Pageable {
  page?: number;
  size?: number;
  sort?: string[];
}

export enum MediaFilePrefix {
  Client = 'client',
  Sponsors = 'sponsors',
  Welcome = 'welcome',
}

interface TargetFile {
  file: File;
}
export interface UploadMediaFileRequest {
  mediaFile: ChosenFile | TargetFile;
  prefix: MediaFilePrefix;
}

export interface UploadMediaFileResponse {
  url: string;
}

export interface GetDataById {
  id: string;
  campaignId?: string;
}

export interface BonusPointsOptions {
  id: string;
  campaignId?: string;
  points: number;
}

export interface ManagerInfo {
  id: string;
  fullName: string;
  email: string;
}

export enum ClientTypes {
  ETS = 'ETS',
  SALES = 'SALES',
  FUNDRAISING = 'FUNDRAISING',
  MARKETING = 'MARKETING',
  TOPGOLF = 'TOPGOLF',
  TRUBLU = 'TRUBLU',
  ATHSOL = 'ATHSOL',
  SV_HEALTH = 'MED',
  BRAND360 = 'BRAND360',
}
