import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useAccountSidebarButtonStyles = makeStyles((theme: Theme) => ({
  sidebarButton: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    gap: '12px',
    borderRadius: '6px',
    padding: '12px',
    fontSize: '14px',
    fontWeight: '600',
    textWrap: 'nowrap',
    whiteSpace: 'nowrap',
    lineHeight: '24px',
    width: '100%',
    marginBottom: '8px',
    transition: 'all 0.2s ease',
    overflow: 'hidden',
  },
  activeSidebarButton: {
    background: theme.palette.common.sidebar.accountsMenu.activeButton.background,
    color: theme.palette.common.sidebar.accountsMenu.activeButton.text,
  },
  inactiveSidebarButton: {
    background: theme.palette.common.sidebar.accountsMenu.inactiveButton.background,
    color: theme.palette.common.sidebar.accountsMenu.inactiveButton.text,

    '&:hover': {
      background: theme.palette.common.sidebar.accountsMenu.inactiveButton.hoverBackground,
      color: theme.palette.common.sidebar.accountsMenu.inactiveButton.hoverText,
    },
  },
}));
