import React, { useEffect, useMemo } from 'react';
import { AccountSidebarButton } from '../AccountSidebarButton/AccountSidebarButton';
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';

import { Box } from '@mui/material';
import { useModal } from '../../../../../hooks/useModal';
import { useAccountsSidebar } from '../../useAccountsSidebar';
import { useDistributorsSelectorStyles } from './DistributorsSelector.styles';
import { useDetectClickOutside } from 'react-detect-click-outside';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

export const DistributorsSelector: React.FC = () => {
  const { isOpen, toggle, close } = useModal();

  const {
    isSidebarOpen,
    distributors,
    activeDistributor,
    handleDistributorClick,
    activeTab,
  } = useAccountsSidebar();

  const classes = useDistributorsSelectorStyles({ isOpen });

  useEffect(() => {
    if (!isSidebarOpen) {
      close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSidebarOpen]);

  const detectClickOutsideRef = useDetectClickOutside({
    onTriggered: close,
  });

  const groupedDistributors = useMemo(() => {
    const sv = distributors.find((d) => d.name === 'SV');
    const svHealth = distributors.find((d) => d.name === 'SV Health');
    const brand360 = distributors.find((d) => d.name === 'Brand 360');
    const socialSmiles = distributors.find((d) => d.name === 'SocialSmiles');
    const test = distributors.find((d) => d.name === 'TEST');

    const restDistributors = distributors.filter((d) => {
      return (
        [sv?.name, svHealth?.name, brand360?.name, socialSmiles?.name, test?.name].includes(
          d.name,
        ) === false
      );
    });

    return [[sv, svHealth], [brand360, socialSmiles], restDistributors, [test]]
      .map((arr) => arr.filter(Boolean))
      .filter((arr) => arr.length > 0);
  }, [distributors]);

  return (
    <>
      <AccountSidebarButton
        isActive={activeTab === 'distributors'}
        onClick={toggle}
        ref={detectClickOutsideRef}
      >
        <Box className={classes.buttonContainer}>
          <Box className={classes.buttonTitle}>
            <LayersOutlinedIcon /> Distributors
          </Box>
          <ChevronRightIcon className={classes.buttonChevron} />
        </Box>
      </AccountSidebarButton>
      <Box className={classes.distributorPopupContainer}>
        <Box className={classes.popupArrowContainer}>
          <Box className={classes.popupArrow} />
        </Box>
        <Box className={classes.distributorPopup}>
          {groupedDistributors.map((distributorGroup, groupIndex) => {
            return (
              <React.Fragment key={groupIndex}>
                {groupIndex !== 0 && <Box className={classes.distributorGroupDivider} />}
                <Box className={classes.distributorGroup}>
                  {distributorGroup.map((distributor) => {
                    if (!distributor) return null;

                    const isActiveDistributor = distributor.id === activeDistributor?.id;

                    return (
                      <button
                        key={distributor.id}
                        onClick={() => {
                          handleDistributorClick(distributor);
                        }}
                        className={`${classes.distributorPopupButton} ${
                          isActiveDistributor ? classes.distributorPopupButtonActive : ''
                        }`}
                      >
                        {distributor.name}
                      </button>
                    );
                  })}
                </Box>
              </React.Fragment>
            );
          })}
        </Box>
      </Box>
    </>
  );
};
