import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { useStyles } from './RewardMessageTab.styles';
import { ElementsStrings } from '../../../../../../common/localization/en';
import { useAppDispatch, useTypedSelector } from '../../../../../../store';
import { Tooltip } from '../../../../../../common/components/Tooltip/Tooltip';
import { ContactMethod } from '../../../../../NewCampaign/HowToRewardCreators/HowToRewardCreators.helpers';
import { TextareaAutosize } from '@mui/base';
import {
  constructMessageFromTemplate,
  LocalMessageVariables,
  replaceMessageBodyWithLocalVariables,
} from '../../../../../../common/components/SMS/SMS.helper';
import { useClientType } from '../../../../../../services/hooks/useClientType';
import { useSmsTemplateVariables } from '../../../../../../services/hooks/useSmsTemplateVariables';
import { RewardFulfillmentMethod } from '../../../../../../api/models/rewardFulfillment';
import { RoundedButton } from '../../../../../../common/components/Button/RoundedButton';
import { SendTestMessageModal } from '../../../../../../common/components/SMS/SendTestMessageModal';
import { useModal } from '../../../../../../hooks/useModal';
import { useSendTestSms } from '../../../../../../services/hooks/useSendTestSms';
import { IPhoneHalfPreview } from '../../../../../../common/components/IPhonePreview/IPhoneHalfPreview';
import RewardCardPreview from '../../../../../../common/components/RewardCardPreview/RewardCardPreview';
import { MessageBox } from '../../../../../../common/components/IPhonePreview/IPhoneSMSPreview';
import { useParams } from 'react-router-dom';
import { useTrackEvent } from '../../../../../../hooks/useTrackEvent';
import { RewardCampaignsEvents } from '../../../../../../common/constants/events/rewardCampaignsEvents';
import { CustomizeRewardMessageModal } from './CustomizeRewardMessageModal/CustomizeRewardMessageModal';
import { getRewardCardUrl } from '../../../../../../store/slices/campaignsSlice';
import { format } from 'date-fns';
import { RewardCard } from '../../../../../../api/models/campaigns';

export const RewardMessageTab: FC = () => {
  const classes = useStyles();
  const { id: campaignId } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();

  const { trackEvent } = useTrackEvent();
  const { isHealthCare } = useClientType();
  const { currentCampaign } = useTypedSelector((state) => state.campaigns);
  const { phoneNumber, name } = useTypedSelector((store) => store.me);
  const {
    venue: { logo, name: companyName },
  } = useTypedSelector((state) => state.venue);
  const me = useTypedSelector((state) => state.me);

  const {
    isOpen: isTestMessageModalOpen,
    open: openTestMessageModal,
    close: closeTestMessageModal,
  } = useModal();

  const {
    isOpen: isCustomizeModalOpen,
    open: openCustomizeModal,
    close: closeCustomizeModal,
  } = useModal();

  const sendTestSMS = useSendTestSms();

  const contactMethodsMap: Record<ContactMethod, string> = {
    PHONE: ElementsStrings.PhoneNumber,
    EMAIL: ElementsStrings.Email,
    WEBSITE: ElementsStrings.URL,
    NONE: ElementsStrings.None,
  };

  const deliveryMethodsMap: Partial<Record<RewardFulfillmentMethod, string>> = {
    MANUAL_SMS: ElementsStrings.Manual,
    AUTOMATIC_SMS: ElementsStrings.Automatic,
  };

  const rewardMessage = replaceMessageBodyWithLocalVariables(
    currentCampaign?.fulfillmentConfig?.sms?.body || '',
    isHealthCare,
    true,
  );

  const templateVariables = useSmsTemplateVariables({
    ignoreVariables: currentCampaign?.isNullReward ? [LocalMessageVariables.RewardName] : [],
    rewardName: currentCampaign?.rewardTitle || '',
  });

  const rewardMessageConstructed = constructMessageFromTemplate(
    rewardMessage,
    templateVariables,
    true,
  );

  if (!currentCampaign) {
    return null;
  }

  const {
    isNullReward,
    cardStyle,
    contact,
    rewardTitle,
    rewardHeader,
    contactMethod,
    isMultiFulfillment,
  } = currentCampaign;

  return (
    <Box className={classes.container}>
      <CustomizeRewardMessageModal
        isOpen={isCustomizeModalOpen}
        onClose={closeCustomizeModal}
        onSendSampleMessageClick={openTestMessageModal}
      />
      <SendTestMessageModal
        modalIsOpen={isTestMessageModalOpen}
        onClose={() => {
          closeTestMessageModal();
        }}
        testMessage={rewardMessageConstructed}
        onTestMessageSendClick={async (data) => {
          const imageCardPayload = await dispatch(
            getRewardCardUrl({
              companyName,
              contact,
              logoUrl: logo,
              userName: me.name,
              rewardHeader,
              rewardTitle: rewardTitle || '',
              cardStyle,
              date: format(new Date(), 'P'),
            }),
          );

          const imageUrl = (imageCardPayload.payload as RewardCard).imageUrl;
          const sendData = imageUrl && !isNullReward ? { ...data, mediaUrl: imageUrl } : data;
          sendTestSMS(sendData);
        }}
        phoneNumber={phoneNumber}
        name={name}
      />
      <Box className={classes.leftSideWrapper}>
        <Typography className={classes.title}>
          {isNullReward ? ElementsStrings.ThankYouTextMessage : ElementsStrings.RewardTextMessage}
        </Typography>
        <Typography className={classes.subtitle}>
          {(isHealthCare
            ? ElementsStrings.ReviewRewardTrublu
            : ElementsStrings.ReviewReward
          ).replace(isNullReward ? 'Reward' : '', '')}
        </Typography>
        <Typography className={classes.settingsText}>{ElementsStrings.Settings}</Typography>
        {!isNullReward && (
          <>
            <Box className={classes.sectionWrapper}>
              <Typography className={classes.sectionTitle} mr="29px">
                {ElementsStrings.Header}
              </Typography>
              <Typography className={classes.sectionValue}>
                {currentCampaign?.rewardHeader}
              </Typography>
              <Tooltip
                content={
                  <Box className={classes.headerTooltip}>{ElementsStrings.HeaderTooltip}</Box>
                }
                maxWidth="250px"
              />
            </Box>

            <Box className={classes.sectionWrapper}>
              <Typography className={classes.sectionTitle} mr="25px">
                {ElementsStrings.Contact}
              </Typography>
              <Typography className={classes.sectionValue}>
                {contactMethodsMap[currentCampaign?.contactMethod as ContactMethod]}
              </Typography>
              <Tooltip
                content={
                  <Box className={classes.contactTooltip}>{ElementsStrings.ContactTooltip}</Box>
                }
              />
            </Box>
          </>
        )}
        <Typography className={classes.sectionTitle} mt="14px">
          {ElementsStrings.Text}
        </Typography>
        <TextareaAutosize
          defaultValue={rewardMessage}
          disabled={true}
          value={rewardMessage}
          className={classes.rewardMessage}
        />
        <Box className={classes.sectionWrapper}>
          <Typography className={classes.sectionTitle} mr="25px">
            {ElementsStrings.Delivery}
          </Typography>
          <Typography className={classes.sectionValue}>
            {
              deliveryMethodsMap[
                currentCampaign?.fulfillmentConfig?.method as RewardFulfillmentMethod
              ]
            }
          </Typography>
          <Tooltip
            content={
              <Box className={classes.deliveryTooltip}>
                {ElementsStrings.DeliveryTooltip.replace(
                  isNullReward ? ElementsStrings.Reward : '',
                  '',
                )}
              </Box>
            }
          />
        </Box>
        <Box className={classes.sectionWrapper}>
          <Typography className={classes.sectionTitle} mr="8px">
            {ElementsStrings.Frequency}
          </Typography>
          <Typography className={classes.sectionValue}>
            {isMultiFulfillment ? ElementsStrings.EveryClip : ElementsStrings.FirstClip}
          </Typography>
          <Tooltip
            content={
              <Box className={classes.frequencyTooltip}>
                {ElementsStrings.FrequencyTooltip.replace(
                  isNullReward ? ElementsStrings.Reward : '',
                  '',
                )}
              </Box>
            }
            maxWidth="250px"
          />
        </Box>
        {!currentCampaign?.endedAt && (
          <Box className={classes.buttonsWrapper}>
            <RoundedButton
              className={classes.customizeButton}
              title={ElementsStrings.Customize}
              onClick={() => {
                trackEvent(RewardCampaignsEvents.OpenCustomizeRewardModal, {
                  campaignId,
                });
                openCustomizeModal();
              }}
            />

            <RoundedButton
              className={classes.sendMessageButton}
              title={ElementsStrings.SendSampleMessage}
              outlined={true}
              onClick={() => {
                openTestMessageModal();
                trackEvent(RewardCampaignsEvents.OpenRewardTestMessageModal, {
                  campaignId,
                });
              }}
            />
          </Box>
        )}
      </Box>

      <Box className={classes.rightSideWrapper}>
        <Typography className={classes.howAppearText}>
          {ElementsStrings.HowTextWillAppear}
        </Typography>
        <Box className={classes.previewWrapper}>
          <IPhoneHalfPreview height={900}>
            {!isNullReward && (
              <Box className={classes.rewardCardWrapper}>
                <RewardCardPreview
                  cardColor={cardStyle}
                  contact={contact}
                  rewardTitle={rewardTitle || ''}
                  rewardHeader={rewardHeader}
                  logo={logo}
                  contactMethod={contactMethod}
                  scaleFactor={0.6}
                />
              </Box>
            )}
            <MessageBox
              width="280px"
              fontSize={16}
              message={rewardMessage}
              boldSubstrings={Object.values(LocalMessageVariables)}
              showStartBlock={false}
            />
          </IPhoneHalfPreview>
        </Box>
      </Box>
    </Box>
  );
};
