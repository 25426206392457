import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useAccountsSidebarStyles } from './AccountsSidebar.styles';

import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import { useAccountsSidebar } from './useAccountsSidebar';
import { AccountSidebarButton } from './components/AccountSidebarButton/AccountSidebarButton';
import { DistributorsSelector } from './components/DistributorsSelector/DistributorsSelector';
import { SidebarBrandLogo } from './components/SidebarBrandLogo/SidebarBrandLogo';
import { getVenueListApiCall } from '../../../store/slices/organizationVenueSlice';

export const AccountsSidebar: React.FC = () => {
  const {
    handleAllAccountsClick,
    handleArchivedAccountClick,
    activeTab,
    openSidebar,
    closeSidebar,
    isSidebarOpen,
    distributors,
  } = useAccountsSidebar();

  const [hasArchiveAccounts, setHasArchiveAccounts] = useState(false);

  useEffect(() => {
    getVenueListApiCall({ deactivated: true, params: { size: 1 } }).then((data) => {
      setHasArchiveAccounts(data.totalItems > 0);
    });
  }, []);

  const classes = useAccountsSidebarStyles({ isSidebarOpen });

  return (
    <Box className={classes.root}>
      <Box className={classes.container} onMouseEnter={openSidebar} onMouseLeave={closeSidebar}>
        <Box className={classes.buttonsContainer}>
          <AccountSidebarButton isActive={activeTab === 'all'} onClick={handleAllAccountsClick}>
            <AppsOutlinedIcon />
            All Accounts
          </AccountSidebarButton>

          {distributors.length > 0 && <DistributorsSelector />}

          {hasArchiveAccounts && (
            <AccountSidebarButton
              isActive={activeTab === 'archived'}
              onClick={handleArchivedAccountClick}
            >
              <Inventory2OutlinedIcon />
              Archived Accounts
            </AccountSidebarButton>
          )}
        </Box>

        <Box>
          <SidebarBrandLogo />
        </Box>
      </Box>
    </Box>
  );
};
