import { useHistory } from 'react-router-dom';
import { DistributorModel } from '../../../api/models/distributors';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { setActiveAffiliate } from '../../../store/slices/affiliatesSlice';
import { setActiveDistributor } from '../../../store/slices/distributorsSlice';
import { setGroupId, setIsArchivedAccount } from '../../../store/slices/organizationVenueSlice';
import { setIsAccountsSidebarOpen } from '../../../store/slices/uiSlice';
import { AllAccountsEventNames } from '../../constants/events/allAccountsEvents';
import { WebsiteRoutes } from '../../constants/routes';

export const useAccountsSidebar = () => {
  const { trackEvent } = useTrackEvent();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const isSidebarOpen = useTypedSelector((state) => state.ui.isAccountsSidebarOpen);

  const { isArchivedAccount } = useTypedSelector((state) => state.OrganizationVenueSlice);

  const { distributors, activeDistributor } = useTypedSelector((state) => state.distributors);

  const openSidebar = () => {
    dispatch(setIsAccountsSidebarOpen(true));
  };

  const closeSidebar = () => {
    dispatch(setIsAccountsSidebarOpen(false));
  };

  const handleArchivedAccountClick = () => {
    trackEvent(AllAccountsEventNames.archivedAccountsButtonClicked);
    localStorage.removeItem('selectedDistributor');
    localStorage.removeItem('selectedAffiliate');
    dispatch(setActiveAffiliate(undefined));
    dispatch(setActiveDistributor(undefined));
    history.push(WebsiteRoutes.ProfileAccounts);
    dispatch(setGroupId(null));
    dispatch(setIsArchivedAccount(true));
  };

  const handleAllAccountsClick = () => {
    trackEvent(AllAccountsEventNames.allAccountsButtonClicked);
    localStorage.removeItem('selectedDistributor');
    localStorage.removeItem('selectedAffiliate');
    dispatch(setActiveAffiliate(undefined));
    dispatch(setActiveDistributor(undefined));
    dispatch(setGroupId(null));
    dispatch(setIsArchivedAccount(false));
    history.push(WebsiteRoutes.ProfileAccounts);
  };

  const handleDistributorClick = (distributor: DistributorModel | undefined) => {
    trackEvent(AllAccountsEventNames.distributorAccountsButtonClicked, {
      distributorId: distributor?.id,
    });
    dispatch(setActiveAffiliate(undefined));
    dispatch(setActiveDistributor(distributor));
    dispatch(setGroupId(distributor?.groupId || null));
    dispatch(setIsArchivedAccount(false));
    // setIsSvAccountSelected(false);

    if (distributor) {
      localStorage.setItem('selectedDistributor', JSON.stringify(distributor));
      localStorage.removeItem('selectedAffiliate');
    }

    history.push(WebsiteRoutes.ProfileAccounts);
  };

  const getActiveTab = () => {
    if (isArchivedAccount) return 'archived';
    if (activeDistributor) return 'distributors';
    return 'all';
  };

  const getActiveTabName = () => {
    const activeTab = getActiveTab();

    if (activeTab === 'archived') {
      return 'Archived Accounts';
    }

    if (activeTab === 'distributors') {
      return `${activeDistributor?.name} Accounts`;
    }

    return 'All Accounts';
  };

  return {
    handleArchivedAccountClick,
    handleAllAccountsClick,
    handleDistributorClick,
    distributors,
    activeDistributor,

    activeTab: getActiveTab(),
    activeTabName: getActiveTabName(),

    isSidebarOpen,
    openSidebar,
    closeSidebar,
  };
};
