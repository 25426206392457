import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '824px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  leftSideWrapper: {
    width: '508px',
    padding: '10px 20px',
  },
  title: {
    color: theme.palette.primary.main,
    fontFamily: 'Poppins',
    fontSize: '26px',
    fontWeight: '600',
    lineHeight: '65px',
    letterSpacing: '-0.52px',
  },
  headerTooltip: {
    width: '240px',
  },
  contactTooltip: {
    width: '200px',
  },
  deliveryTooltip: {
    width: '200px',
  },
  frequencyTooltip: {
    width: '227px',
  },
  subtitle: {
    fontSize: '18px',
    fontFamily: 'Poppins',
    color: '#475467',
    lineHeight: '24px',
  },
  settingsText: {
    fontSize: '18px',
    fontFamily: 'Poppins',
    color: '#101828',
    lineHeight: '28px',
    fontWeight: 600,
    marginTop: '18px',
    marginBottom: '12px',
  },
  sectionWrapper: {
    display: 'flex',
    marginTop: '6px',
    alignItems: 'center',
  },
  rightSideWrapper: {
    width: '286px',
    paddingTop: '12px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'relative',
  },
  sectionTitle: {
    fontSize: '14px',
    fontFamily: 'Poppins',
    color: theme.palette.primary.main,
    lineHeight: '20px',
    fontWeight: 500,
  },
  sectionValue: {
    fontSize: '16px',
    fontFamily: 'Poppins',
    color: '#344054',
    lineHeight: '20px',
    fontWeight: 500,
  },
  rewardMessage: {
    resize: 'none',
    width: '443px !important',
    height: '130px !important',
    scrollBehavior: 'auto',
    overflow: 'auto !important',
    border: '1px solid #D2D2D2',
    borderRadius: '2px',
    marginTop: '7px',
    marginBottom: '14px',
    '&::-webkit-scrollbar': {
      width: '6px',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#D9D9D9',
      borderRadius: '6px',
    },
  },
  buttonsWrapper: {
    display: 'flex',
    width: 'fit-content',
    marginTop: '22px',
  },
  customizeButton: {
    fontFamily: 'Inter',
    width: '116px',
    height: '40px',
    padding: '0',
    borderRadius: '8px',
    marginRight: '12px',
    '&:hover': {
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      transform: 'scale(0.99)',
    },
    '&:disabled': {
      backgroundColor: theme.palette.primary.main,
    },

    '& p': {
      fontSize: '14px',
      fontWeight: 600,
    },
  },
  sendMessageButton: {
    fontFamily: 'Inter',
    width: '194px',
    height: '40px',
    padding: '0',
    borderRadius: '8px',
    border: '1px solid #5242EA',
    background: '#fff',
    '&:hover': {
      background: '#fff',
      border: '1px solid #5242EA',
    },
    '&:active': {
      transform: 'scale(0.99)',
    },
    '&:disabled': {
      backgroundColor: theme.palette.common.white,
    },

    '& p': {
      fontSize: '14px',
      fontWeight: 600,
      color: theme.palette.primary.main,
    },
  },
  howAppearText: {
    fontSize: '13px',
    fontFamily: 'Inter',
    color: '#475467',
    lineHeight: '18px',
    fontWeight: 400,
    textAlign: 'center',
    width: '230px',
  },
  rewardCardWrapper: {
    marginTop: '-2px',
    marginBottom: '4px',
  },
  previewWrapper: {
    transform: 'scale(0.6)',
    position: 'absolute',
    top: '-120px',
  },
}));
